@import '../../assets/scss/variables.scss';

.confirm-box__content {
    color: #000 !important;
}
.need-help {
    float: right;
    padding: 10px 0px 0px 12px;
    width: 118px;
    border: 1px solid #7180924E;
    border-radius: 10px;
    opacity: 1;
    color: #fff;
    cursor: pointer !important;
}

.myoffer-list{
    list-style-type: none;
    padding-left: 0;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    margin-top: 40px;
    grid-gap: 40px;
    @media screen and (max-width:1024px) {
        grid-template-columns: repeat(2, 1fr);
    }
    @media screen and (max-width:640px) {
        grid-template-columns: repeat(1, 1fr);
        grid-gap: 20px;
    }
    li{
        .offer-cards{
            background: transparent linear-gradient(297deg, #FFCCB5 0%, #FCFCFC 100%) 0% 0% no-repeat padding-box;
            border-radius: 10px;
            color: $dark-color;
            height: 100%;
            display: flex;
            flex-direction: column;
            .offer-cards-upper{
                padding: 25px;
                display: flex;
                .left-image{
                    width: 65px;
                    height: 65px;
                    min-width: 65px;
                    border-radius: 15px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-right: 12px;
                    background: $white;
                    img{
                        max-width: 100%;
                    }
                }
                h3{
                    font-size: 20px;
                    font-weight: 600;
                    color: $dark-color;
                    margin-bottom: 8px;
                }
                p{
                    font-size: 14px;
                    margin-bottom: 0;
                    line-height: 1.2;
                }
            }
            .offer-cards-bottom{
                padding: 18px 25px;
                font-size: 15px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                border-top: 2px dashed #7180924E;
                margin-top: auto;
                .left-col{
                    display: flex;
                    align-items: center;
                    .icon {
                        margin-right: 8px;
                    }
                }
            }
        }
        .add-offer{
            border: 2px dashed #718092;
            background: $dark-color;
            border-radius: 10px;
            cursor: pointer;
            transition: all ease-in 0.1s;
            padding: 15px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            font-size: 15px;
            max-width: 220px;
            height: 100%;
            @media screen and (max-width:991px) {
                max-width: 100%;
            }
            @media screen and (max-width:640px) {
                max-width: 100%;
            }
            .plus-icon{
                background: #6FC3CE;
                width: 40px;
                height: 40px;
                border-radius: 10px;
                display: flex;
                align-items: center;
                justify-content: center;
                transition: all ease-in 0.1s;
                margin-bottom: 12px;
                .icon {
                fill: $white;
                }
            }
            &:hover{
                border-color: rgba($primary-color, 0.5);
                .plus-icon{
                background: $primary-color;
                }
            }
        }
    }
}
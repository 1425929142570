@import '../../assets/scss/variables.scss';

.outletmgt-table{
    .ant-table-body{
        max-height: calc(100vh - 550px);
        overflow-y: auto !important;
    }
}
.need-help {
  float: right;
  padding: 10px 0px 0px 12px;
  width: 118px;
  border: 1px solid #7180924E;
  border-radius: 10px;
  opacity: 1;
  color: #fff;
  cursor: pointer !important;
}
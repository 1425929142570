.date-picker-content {
    padding-top: 8px;
    padding-bottom: 8px;
    width: 200px;
    display: flex;
    flex-direction: column;

    .ant-select {

        border-radius: 4px;
        border: 1px solid #718092;

        .ant-select-selector {
            height: 40px !important;
            background-color: transparent !important;

            .ant-select-selection-item {
                color: black;
                padding-top: 4px;
            }
        }


    }

    .or-text {
        text-align: center;
        font-size: 16px;
        margin-top: 12px;
    }

    .generate-button {
        margin-top: 24px;
        width: 100%;
    }

    .start-date {
        margin-top: 12px;
    }

    .end-date {
        margin-top: 24px;
    }

    .date-picker-float-label {
        position: relative;

        .ant-picker {
            border-radius: 4px;
            border: 1px solid #718092;
            width: 100%;

        }

        label {
            font-weight: normal;
            position: absolute;
            pointer-events: none;
            transition: 0.2s ease all;
            z-index: 2;
            top: -9px;
            font-size: 12px;
            left: 14px;
            background: #ffffff;
            text-transform: uppercase;
        }
    }
}
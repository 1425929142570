@import '../../assets/scss/variables.scss';

.ant-pagination-item{
    background-color: none !important;
    border: none !important;
}
.need-help {
  float: right;
  padding: 10px 0px 0px 12px;
  width: 118px;
  border: 1px solid #7180924E;
  border-radius: 10px;
  opacity: 1;
  color: #fff;
  cursor: pointer !important;
}

.filter-row{
    margin-top: 55px;
    margin-bottom: 15px;
    .filter-left{
        display: flex;
        align-items: center;
        h3{
            margin-bottom: 0;
            font-size: 18px;
            font-weight: 600;
        }
        .ant-input-affix-wrapper{
            margin-left: 15px;
            background: none;
            border-width: 0 0 0 1px !important;
            border-color: rgba($white, 0.06);
            box-shadow: none;
            outline: none;
            width: 50px;
            &:hover {
                width: 150px;
                border-color: $primary-color;
            }
            .ant-input {
                background: none;
                border: 0;
                color: $white;
            }
            .ant-input-suffix{
                .icon{
                    fill: $label-color;
                }
            }
        }
    }
    .filter-right{
        display: flex;
        justify-content: flex-end;
        @media screen and (max-width:767px) {
            margin-top: 30px;
        }
        @media screen and (max-width:400px) {
            display: block;
        }
        .icons-btn{
            margin-left: 10px;
            min-width: 40px;
            @media screen and (max-width:400px) {
                width: 50%;
                margin: 15px 0 0 0;
            }
        }
        .date-select-row{
            display: flex;
            align-items: center;
            position: relative;
            width: 100%;
            max-width: 300px;
            margin-right: 10px;
            @media screen and (max-width:400px) {
                margin-right: 0;
                max-width: 100%;
                width: 100%;
            }
            .date-col{
                position: relative;
                @media screen and (max-width:400px) {
                    width: 50%;
                }
                .ant-picker{
                    height: 40px;
                    background: $dark-color;
                    border-color: #7180924E;
                    @media screen and (max-width:400px) {
                        width: 100%;
                    }
                    .ant-picker-suffix{
                        .icon {
                            fill: $white;
                        }
                    }
                    .ant-picker-input{
                        input {
                            color: $white;
                        }
                    }
                }
                label {
                    position: absolute;
                    top: -13px;
                    left: 8px;
                    z-index: 1;
                    background: #12263E;
                    padding: 2px 6px;
                    color: $label-color;
                    font-size: 12px;
                }
                &:first-child{
                    .ant-picker{
                        border-radius: 5px 0 0 5px;
                    }
                }
                &:last-child{
                    .ant-picker{
                        border-radius: 0 5px 5px 0;
                        border-left: 0;
                    }
                }
            }
        }
    }
}

.redemption-table{
    .ant-table-body{
        max-height: calc(100vh - 550px);
        overflow-y: auto !important;
    }
    tbody{
        tr{
            cursor: pointer;
        }
    }
}

.redemption-modal{
    .ant-modal-close{
        top: 15px;
        right: 15px;
        .ant-modal-close-x{
            width: 25px;
            height: 25px;
            line-height: 25px;
            .icon {
                fill: $white;
                width: 25px;
            }
        }
    }
    .ant-modal-content{
        background: $dark-color;
        color: $white;
        font-size: 16px;
        border-radius: 10px;
        label {
            color: $label-color;
            font-size: 14px;
        }
        .ant-divider{
            max-width: 114px;
            min-width: 114px;
            border-color: $primary-color;
        }
        .upper-details{
            .upper-image{
                width: 64px;
                height: 64px;
                border-radius: 64px;
                overflow: hidden;
                margin-bottom: 15px;
                img{
                    width: 100%;
                }
            } 
            h3{
                font-size: 20px;
                font-weight: 600;
            }
            p{
                font-size: 16px;
                margin-bottom: 0;
            }
        }
        .bottom-details{
            .row{
                padding: 10px 0;
                .payment-icon{
                    width: 25px;
                    height: 25px;
                    border-radius: 25px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-left: 4px;
                    background: transparent linear-gradient(302deg, #FFCCB5 0%, #FCFCFC 100%) 0% 0% no-repeat padding-box;
                }
            }
        }
    }
    .ant-modal-footer{
        text-align: left;
        border-color: #7180924E;
        padding: 20px;
        .ant-btn{
            width: 50px;
            height: 50px;
            border-radius: 10px;
            line-height: 53px;
            padding: 0;
            text-align: center;
            background: #12263E;
            .icon {
                fill: $primary-color;
            }
            &:hover{
                background: $primary-color;
                .icon {
                    fill: $white;
                }
            }
        }
    }
}

.ant-select-selector {
    position: relative;
    background-color: #132C4A!important;
    border-color: #7180924E!important;
    color: #d9d9d9!important;
    height:35px!important;
    font-size: 15px!important;
    border-radius: 4px!important;
    
}
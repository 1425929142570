@import "../../../assets/scss/variables.scss";

.sidebar-inner{
    display: flex;
    flex-direction: column;
    height: 100%;
    min-height: calc(100vh - 64px);
    @media screen and (max-width:991px) {
        max-height: calc(100vh - 32px);
        min-height: calc(100vh - 32px);
    }
    @media screen and (max-width:640px) {
        max-height: calc(100vh - 64px);
        min-height: calc(100vh - 64px);
    }
    .sidebar-upper{
        .logo-section{
            padding: 15px 30px 20px 30px;
            border-bottom: 1px solid rgba($white, 0.6);
            @media screen and (max-width:640px) {
                padding: 15px 30px 15px 30px;
            }
            img{
                max-width: 40px;
            }
            h3{
                margin-bottom: 0;
                color: $secondary-color;
                margin-top: 10px;
                font-weight: 600;
                font-size: 16px;
            }
        }
        .overall-status{
            display: flex;
            position: relative;
            border: 1px dashed #FFFFFF;
            margin: 15px 10px 15px 15px;
            @media screen and (max-width:640px) {
                margin: 15px 10px 15px 15px;
            }
            label {
                position: absolute;
                width: 82px;
                text-align: center;
                border-radius: 2px;
                height: 19px;
                line-height: 19px;
                background: $white;
                color: $dark-color;
                font-size: 12px;
                left: 8px;
                top: -10px;
            }
            .os-col{
                padding: 10px 0 10px 15px;
                small{
                    color: $primary-color;
                }
                h3{
                    font-size: 18px;
                    font-weight: 600;
                    color: $dark-color;
                    margin-bottom: 0;
                }
                p{
                    margin-bottom: 0;
                    font-size: 12px;
                    color: $dark-color;
                }
            }
        }
        .menu-section{
            padding: 20px 30px 30px 30px;
            @media screen and (max-width:640px) {
                padding: 15px 30px 20px 30px;
            }
            .ant-menu{
                background: none;
                .ant-menu-item{
                    padding: 6px 0;
                    height: auto;
                    line-height: abs($number: 0);
                    &.ant-menu-item-selected{
                        background: none;
                        border: 0;
                        a{
                            color: $dark-color !important;
                        }
                    }
                    &:hover{
                        .icon {
                            transform: scale(1.15);
                        }
                    }
                    .ant-menu-title-content{
                        a{
                            display: flex;
                            align-items: center;
                            .icon {
                                width: 30px;
                                height: 30px;
                                background: $white;
                                border-radius: 5px;
                                padding: 8px;
                                margin-right: 15px;
                                fill: $dark-color;
                                transition: all ease-in 0.1s;
                            }
                            &.selected{
                                font-weight: 600;
                                .icon {
                                    fill: $primary-color;
                                }
                            }
                            &:hover{
                                color: $dark-color !important;
                            }
                        }
                    }
                }
            }
        }
    }
    .sidebar-bottom{
        margin-top: auto;
        .app-download{
            margin: auto auto 15px auto;
            max-width: 180px;
            text-align: center;
            position: relative;
            .download-caption{
                position: absolute;
                bottom: 2%;
                left: 0;
                right: 0;
                @media screen and (max-width:640px) {
                    bottom: -5%;
                }
                h4{
                    font-size: 17px;
                    font-weight: 600;
                    color: $dark-color;
                    margin-bottom: 3px;
                }   
                p{
                    font-size: 12px;
                    color: $dark-color;
                }
                .btn-row{
                    @media screen and (max-width:640px) {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                    .ant-btn{
                        background: $white;
                        width: 50px;
                        height: 50px;
                        line-height: 53px;
                        border-radius: 7px;
                        padding: 0;
                        margin: 0 6px;
                        @media screen and (max-width:640px) {
                            width: 35px;
                            height: 35px;
                            line-height: 38px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                        }
                        &:hover {
                            .icon {
                                fill: $primary-color;
                            }
                        }
                    }
                }
            }
            img{
                width: 100%;
                @media screen and (max-width:640px) {
                    max-width: 110px;
                }
            }
        }
        .logout-bottom{
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 12px 20px;
            border-top: 1px dashed $white;
            color: $dark-color;
            font-weight: 600;
            font-size: 16px;
            .ant-btn{
                width: 35px;
                height: 35px;
                border-radius: 7px;
                line-height: 37px;
                padding: 0;
                text-align: center;
                .icon {
                    fill: $dark-color;
                }
                &:hover{
                    background: $primary-color;
                    .icon {
                        fill: $white;
                    }
                }
            }
        }
    }
}